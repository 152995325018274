import React from 'react';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { Footer } from '../components/Footer';
import { Posts } from '../components/Posts';

const IndexPage = () => (
  <Layout>
    <SEO title="Posts" />
    <Posts />
    <Footer />
  </Layout>
);

export default IndexPage;
