import React, { FC, useState, useEffect } from 'react';

import LikeIcon from '../../images/like-icon.inline.svg';
import styles from './Posts.module.css';

export const get = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
};

type Post = {
  url: string,
  formattedDate?: string,
  text: string,
  previewSrc?: string,
  likes: number,
  tags?: Array<string>,
}

type Error = {
  error: string,
}

const Post: FC<Post> = ({ formattedDate, text, previewSrc, likes, url, tags }) => {
  return (
    <section className={styles.post}>
      <aside className={styles.date}>{formattedDate && formattedDate}</aside>
      <article className={styles.content}>
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
        {previewSrc && (
          <div className={styles.image}>
            <img src={previewSrc} alt="Graph" />
          </div>
        )}
        <footer className={styles.footer}>
          <div className={styles.likes}>
            <LikeIcon className={styles.likeIcon} />
            <span>{likes} reaction{likes === 1 ? '' : 's'}</span>
          </div>
          <div className={styles.link}>
            Open on <a href={url}>LinkedIn</a>
          </div>
        </footer>
      </article>
      <aside className={styles.tags}>
        {tags && tags.map((tag: string) => (
          <div className={styles.tagItem}>
            <a className={styles.tag} key={`tag-${tag}`} href={`/posts?tag=${tag}`}>#{tag}</a>
          </div>
        ))}
      </aside>
    </section>
  );
};

export const Posts = () => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<Array<Post> | Error | null>(null);
  const fetchData = async () => {
    try {
      const res = await get('https://europe-west2-alona-yunda.cloudfunctions.net/posts');
      setResponse(res);
    } catch(e) {
      setResponse({ error: e.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>
  }

  if (!response) {
    return <div>No data</div>;
  }

  if ('error' in response) {
    return <div>{response.error}</div>;
  }

  return response.map(postData => (
    <Post key={`post-${postData.url}`} {...postData} />
  ));
};
